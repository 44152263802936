@import './Colours.module.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $grey-1;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.link {
  text-decoration: underline;
  text-decoration-style: dotted;
}

h1 {
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  color: $black;
  margin-top: 0;
  margin-bottom: 12px;
}

h2 {
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  margin-top: 8px;
  margin-bottom: 0;
}

h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

h4 {
  font-weight: bold;
  text-transform: uppercase;
  color: $grey-6;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 16px;
  margin: 0;
}

h5 {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

h6 {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
}

.Bold {
  font-weight: 600;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}
